export default [
  {
    title: "داشبورد",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "کاربران",
    route: "users",
    icon: "UserIcon",
  },
  {
    title: "سفارشات",
    route: "orders",
    icon: "ShoppingCartIcon",
  },
  {
    title: "تیکت ها",
    route: "tickets",
    icon: "MessageSquareIcon",
  },
  {
    title: "محصولات",
    route: "products",
    icon: "ArchiveIcon",
  },
  {
    title: "اسلایدر",
    route: "slider",
    icon: "CopyIcon",
  },
  {
    title: "محتوا متنی",
    route: "text-content",
    icon: "FileTextIcon",
  },
  {
    title: "دسته بندی ها",
    route: "categories",
    icon: "GridIcon",
  },
];
